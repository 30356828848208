.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1280px;
  margin: 160px auto 160px;
}

.contacts__title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 66px;

  color: #172D60;
  margin: 0;
}

.contacts__content {
  display: flex;
  justify-content: space-between;
  margin: 64px 0 0;
  width: 100%;
}

.contacts__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.contacts__text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  color: #172D60;
  margin: 0;
  max-width: 484px;
}

.contacts__row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contacts__icon {
  background-repeat: no-repeat;
  background-size: cover;
}

.contacts__icon_call {
  background-image: url(../../images/phone-icon.svg);
  width: 18px;
  height: 18px;
}

.contacts__icon_loc {
  background-image: url(../../images/loc-icon.svg);
  width: 16px;
  height: 24px;
}

.contacts__icon_mail {
  background-image: url(../../images/mail-icon.svg);
  width: 24px;
  height: 16px;
}

.contacts__icon_time {
  background-image: url(../../images/time-icon.svg);
  width: 24px;
  height: 24px;
}

.contacts__question {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;

  color: #172D60;
  margin: 72px 0 0;
}

.contacts__question_mobile {
  display: none;
}

.contacts__btn {
  margin: 16px 0 0;
  width: 240px;
  height: 64px;
  background: linear-gradient(90deg, #B3E53F 0%, #96E6A1 100%);
  border-radius: 40px;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: .5s;
}

.contacts__btn_mobile {
  display: none;
}

.contacts__btn:hover {
  background: linear-gradient(90deg, #B3FF02 0%, #96E6A1 100%);
  box-shadow: 2px 2px 8px rgba(23, 45, 96, 0.4);
}

.contacts__btn:active {
  background: linear-gradient(90deg, #E4F8B6 0%, #96E6A1 100%);
}

.contacts__btn-icon {
  background-image: url(../../images/whatsapp-icon.svg);
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: cover;
}

.contacts__btn-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  color: #172D60;
  margin: 0;
  text-decoration: none;
}

.contacts__map {
  width: 730px;
  height: 440px;
}

@media screen and (min-width: 320px) and (max-width: 744px) {
  .contacts {
    width: 100%;
    margin: 40px auto 40px;
  }
  
  .contacts__title {
    font-size: 24px;
    line-height: 34px;
  }
  
  .contacts__content {
    flex-direction: column;
    margin: 16px 0 0;
    gap: 14px;
    align-items: center;
  }
  
  .contacts__info {
    gap: 8px;
    width: 280px;
  }

  .contacts__icon_loc {
    margin-bottom: 24px;
  }
  
  .contacts__text {
    font-size: 16px;
    line-height: 26px;
    max-width: 256px;
  }
  
  .contacts__question {
    font-size: 18px;
    line-height: 28px;
    max-width: 220px;
    text-align: center;
    margin: 26px 0 0;
  }

  .contacts__question_desktop {
    display: none;
  }

  .contacts__question_mobile {
    display: inline;
  }
  
  .contacts__btn {
    width: 154px;
    height: 48px;
  }

  .contacts__btn_mobile {
    display: flex;
  }

  .contacts__btn_desktop {
    display: none;
  }
  
  .contacts__btn-text {
    font-size: 16px;
    line-height: 26px;
  }
  
  .contacts__map {
    width: 100%;
    height: 320px;
    margin: 14px auto 0;
  }
}