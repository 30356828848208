.services-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 160px;
}

.services-page__main {
  width: 100%;
  height: 640px;
  background-image: url(../../images/services-page-back.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.services-page__main-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 74px;
  /* or 116% */
  
  
  color: #F6FEFF;
  margin: 184px 0 234px calc((100% - 1280px) / 2);
  max-width: 530px;
}

.services-page__services {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0 0;
}

.services-page__services-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  /* identical to box height, or 114% */
  
  
  color: #172D60;
  margin: 0 0 48px;
}

.services-page__section {
  margin: 16px 0 0;
}

.services-page__section-header {
  width: 840px;  
  background: #F6FEFF;
  border: 1px solid #172D60;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 1s;
}

.services-page__section-header_opened {
  background-color: #C2F3FB;
}

.services-page__section-name {
  margin: 19px 0 19px 32px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */
  
  
  color: #172D60;
}

.services-page__section-button {
  width: 40px;
  height: 40px;
  margin: 0 12px 0 0;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  padding: 0;
  background-image: url(../../images/service-btn.svg);
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: 1s;
  border-radius: 50%;
}

.services-page__section-button_rotated {
  transform: rotate(-180deg);
  background-color: #C2F3FB;
}

.ReactCollapse--collapse {
  max-width: 1009px;
  transition: height 1s;
}

.services-page__section-content {
  margin: 37px 0 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 880px;
}

.services-page__service {
 display: flex;
 justify-content: space-between;
 align-items: flex-start;
}

.services-page__service_header {
  margin-bottom: 5px;
}

.services-page__service-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */
  
  
  color: #172D60;
  margin: 0;
}

.services-page__service-text_title {
  font-weight: 600;
  margin: 16px 0 8px 220px;
}

.services-page__service-text_header {
  font-weight: 600;
  font-size: 18px;
}

.services-page__service-text_code {
  width: 178px;
}

.services-page__service-text_name {
  width: 508px;
}

.services-page__service-text_price {
  width: 109px;
}

.services-page__section-caption {
  display: flex;
  width: 840px;
  justify-content: space-between;
  height: 64px;
  margin: 16px 0 0;
}

.services-page__section-caption-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */
  
  
  color: #172D60;
  margin: 0;
  max-width: 640px;
  align-self: flex-start;
}

.services-page__section-caption-button {
  width: 40px;
  height: 40px;
  margin: 0 12px 0 0;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  padding: 0;
  background-image: url(../../images/service-btn.svg);
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  align-self: flex-end;
  transition: 1s;
  border-radius: 50%;
}

.services-page__section-caption-button_rotated {
  transform: rotate(-180deg);
  background-color: #C2F3FB;
}

.services-page__btn {
  margin: 40px auto 0;
  width: 240px;
  height: 64px;
  background: linear-gradient(90deg, #B3E53F 0%, #96E6A1 100%);
  border-radius: 40px;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: .5s;
}

.services-page__btn:hover {
  background: linear-gradient(90deg, #B3FF02 0%, #96E6A1 100%);
  box-shadow: 2px 2px 8px rgba(23, 45, 96, 0.4);
}

.services-page__btn:active {
  background: linear-gradient(90deg, #E4F8B6 0%, #96E6A1 100%);
}

.services-page__btn-icon {
  background-image: url(../../images/whatsapp-icon.svg);
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: cover;
}

.services-page__btn-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */
  
  
  /* Dark blue */
  
  color: #172D60;
  margin: 0;
  text-decoration: none;
}

.services-page__services-caption {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */
  
  
  color: #172D60;
  max-width: 634px;
  margin: 80px auto 0;
}

@media screen and (min-width: 320px) and (max-width: 744px) {
  .services-page {
    width: 280px;
    padding: 0;
  }
  
  .services-page__main {
    display: none;
  }
  
  .services-page__services {
    width: 280px;
    margin: 0;
    z-index: 3;
  }
  
  .services-page__services-title {
    font-size: 24px;
    line-height: 34px;
    margin: 0 0 8px;
  }
  
  .services-page__section {
    margin: 8px 0 0;
  }
  
  .services-page__section-header {
    width: 280px;  
    height: 56px;
    background-color: rgba(0, 0, 0, 0);
  }

  .services-page__section-header_opened {
    background-color: #C2F3FB;
  }
  
  .services-page__section-name {
    margin: 0 0 0 24px;
    font-size: 18px;
    line-height: 26px;
    max-width: 170px;
  }
  
  .services-page__section-button {
    margin: 0 16px 0 0;
  }
  
  .ReactCollapse--collapse {
    max-width: 280px;
  }
  
  .services-page__section-content {
    margin: 16px 0 0;
    gap: 8px;
    width: 280px;
  }
  
  .services-page__service_header {
    margin-bottom: 10px;
  }
  
  .services-page__service-text {
    font-size: 14px;
    line-height: 24px;
  }
  
  .services-page__service-text_title {
    font-weight: 600;
    margin: 2px auto 0;
    text-align: center;
  }
  
  .services-page__service-text_header {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
  }
  
  .services-page__service-text_code {
    width: 58px;
    word-wrap: break-word;
  }
  
  .services-page__service-text_name {
    width: 140px;
  }
  
  .services-page__service-text_price {
    width: 54px;
  }
  
  .services-page__section-caption {
    flex-direction: column-reverse;
    width: 280px;
    height: min-content;
    margin: 8px 0 0;
  }
  
  .services-page__section-caption-text {
    font-size: 14px;
    line-height: 24px;
    max-width: 230px;
  }
  
  .services-page__section-caption-button {
    margin: 0 0 16px;
    align-self: center;
  }

  .services-page__btn {
    margin: 24px auto 0;
    width: 154px;
    height: 48px;
  }
  
  .services-page__btn-text {
    font-size: 16px;
    line-height: 26px;
  }
  
  .services-page__services-caption {
    display: none;
  }
}