.stocks {
  display: flex;
  flex-direction: column;
  margin: 160px auto 0;
  align-items: center;
}

.stocks__title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 66px;
  /* identical to box height, or 118% */
  
  
  /* Dark blue */
  
  color: #172D60;
  margin: 0;
}

.stocks__content {
  margin: 64px auto 0;
  display: flex;
  gap: 40px;
  align-items: center;
}

.stocks__stock {
  border-radius: 16px;
  width: 840px;
  height: 480px;
  object-fit: cover;
}

.stocks__stock_mobile {
  display: none;
}

.stocks__arrow {
  width: 40px;
  height: 88px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  background-image: url(../../images/arrow.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: .3s;
  cursor: pointer;
  padding: 0;
}

.stocks__arrow:hover {
  background-image: url(../../images/arrow-hover.svg);
}

.stocks__arrow:active {
  background-image: url(../../images/arrow-active.svg);
}

.stocks__container {
  height: 480px;
  width: 840px;
}

.stocks__stock-container {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.stocks__arrow_right {
  transform: rotate(180deg);
}

@media screen and (min-width: 320px) and (max-width: 744px) {
  .stocks {
    margin: 40px auto 0;
  }
  
  .stocks__title {
    font-size: 24px;
    line-height: 34px;
  }
  
  .stocks__content {
    margin: 16px auto 0;
    position: relative;
  }
  
  .stocks__stock {
    width: 320px;
    height: 320px;
  }

  .stocks__stock_desktop {
    display: none;
  }

  .stocks__stock_mobile {
    display: block;
  }
  
  .stocks__arrow {
    width: 16px;
    height: 48px;
    background-image: url(../../images/arrow-320.svg);
    position: absolute;
    z-index: 2;
    bottom: 16px;
  }
  
  .stocks__arrow:hover {
    background-image: url(../../images/arrow-320.svg);
  }
  
  .stocks__arrow:active {
    background-image: url(../../images/arrow-320.svg);
  }

  .stocks__arrow_right {
    right: 16px;
  }

  .stocks__arrow_left {
    left: 16px;
  }

  .stocks__container {
    height: 320px;
    width: 320px;
  }
}