.header {
  width: 1280px;
  display: flex;
  margin: 0 auto;
  padding-top: 24px;
  justify-content: space-between;
  max-height: 50px;
}

.header__logo {
  width: 182px;
  height: 50px;
  background-image: url(../../images/logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.header__links {
  display: flex;
  gap: 28px;
  padding: 0;
  list-style: none;
  min-height: 26px;
}

.header__link a {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */
  
  
  /* Dark blue */
  
  color: #172D60;
  margin: 0;
  text-decoration: none;
  transition: .3s;
  cursor: pointer;
}

.header__link:hover a {
  color: #0542D0;
}

.header__link:active a {
  color: #436DD1;
}

.header__call-block {
  display: flex;
  gap: 8px;
  align-items: center;
}

.header__call-icon {
  background-image: url(../../images/phone-icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}

.header__call-number {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */
  
  
  /* Dark blue */
  
  color: #172D60;
  margin: 0;
  text-decoration: none;
}

.header__call-number a {
  color: #172D60;
  text-decoration: none;
}

.header__menu-icon {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 744px) {
  .header {
    width: 280px;
    max-height: 32px;
    position: relative;
  }

  .header__menu-icon {
    display: block;
    width: 24px;
    height: 22px;
    background-image: url(../../images/menu-icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  .header__logo {
    width: 120px;
    height: 32px;
  }
  
  .header__links {

    display: none;
  }

  .header__links_visible {
    display: none;
  }

  .header__call-block {
    display: none;
  }
}