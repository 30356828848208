@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-Italic.eot');
	src: local('Open Sans Italic'), local('OpenSans-Italic'),
		url('OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
		url('OpenSans-Italic.woff') format('woff'),
		url('OpenSans-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-Regular.eot');
	src: local('Open Sans Regular'), local('OpenSans-Regular'),
		url('OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('OpenSans-Regular.woff') format('woff'),
		url('OpenSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-SemiBold.eot');
	src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
		url('OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('OpenSans-SemiBold.woff') format('woff'),
		url('OpenSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

